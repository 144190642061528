<template>
    <!-- User Profile Communication Info Tab Content View From Account Profile -->
    <div class="tabs-content-firstblock right-tab">
        <div>
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive ml-2">
                    <div>
                        <span>Profile</span>
                        <div>Communication</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-communication mr-2" style="margin-left: 10px;">
            <div class="" style="background-color: #fff;border-radius: 0px 6px 6px 0px;">
                <div class="d-flex justify-content-between align-items-center communic-info" style="background-color: #00448b;border-radius: 0px 6px 0 0;">
                    <h6 class="subHeaderTitle my-2 text-white pl-6">
                        <i class="crmnew-phone iconsize-16 iconsize" style="font-size: 14px; color: #fff;"></i>
                        <span class="pl-2 fs-14 fw-500 profile-text">Communication</span>
                    </h6>
                    <div v-if="$route.params.id == loggedInUser.user_id" style="margin-right: 10px;">
                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                            <button v-if="!edit_enable" class="btn btn-icon pointer ml-2 mr-2 text-capitalize" @click="enableEdit" style="padding: 3px 8px !important;background: rgb(255, 255, 255);border-radius: 3px !important;">
                                <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                                <span class="mx-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                            </button>
                        </el-tooltip>
                    </div>
                </div>
                <div class="mx-4 mb-3" style="padding: 0 10px;" v-if="ajax_call_in_progress">
                    <div class="flex-container py-3">
                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                    </div>
                </div>
                <div v-else class="mx-4 mb-3" style="padding: 0 10px;">
                    <div class="infoHeader pb-4">
                        <!-- <div class=" mt-1">
                            <div class="d-flex align-items-center new-contactView">
                                <div class="text-secondary profile-content-left-text profile-content-left-text-communication">Business Email</div>
                                <div class="w-100">
                                    <div class="d-flex justify-content-end align-items-center new-action-btns"
                                        v-if="edit_primary_email && (communication_data.primary_email.length == 0 || communication_data.primary_email_verified == false)" style="position: absolute;top:10px">
                                        <div class="pointer" @click="resetValue('primary_email')">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                        </div>
                                        <div class="ml-2 pointer">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_primary_email" style="position: absolute;right:19px;top:45px">
                                        <span class="fs-13 text-right d-block">{{(communication_data.primary_email).length}}/320</span>
                                    </div>
                                    <el-tooltip class="item" effect="dark" content="Click to edit"
                                            placement="top">
                                    <input name="primary_email" class="input-field-underline" v-validate="'required|email'"
                                        @focus="editValue('primary_email')" style="padding-top: 6px !important;"
                                        :readonly="!edit_primary_email"
                                        v-model="communication_data.primary_email">
                                    </el-tooltip>
                                    <span class="validation" v-show="errors.has('primary_email')">
                                        {{errors.first('primary_email')}}
                                    </span>
                                </div>
                            </div>
                        </div> -->
                        <div class="mt-3">
                            <div class="d-flex new-contactView">
                                <div class="text-secondary profile-content-left-text profile-content-left-text-communication">Primary Email</div>
                                <div class="w-100">
                                    <div class="d-flex justify-content-end align-items-center new-action-btns"
                                        style="position: absolute;top:10px">
                                        <!-- <div v-if="edit_primary_email && (communication_data.primary_email_verified == false)" class="pointer" @click="resetValue('primary_email', '0')">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                        </div>
                                        <div v-if="edit_primary_email && (communication_data.primary_email_verified == false)" class="ml-2 pointer" @click="addUpdateCommunicationData('primary_email', '0')">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                        </div> -->
                                        <div v-if="edit_enable == false && communication_data.primary_email != '' && communication_data.primary_email_verified == false" class="pointer" @click="sendOtpForPhoneEmail('primary_email_verify')">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/verify-phone.svg"
                                                    width="14px">
                                        </div>
                                        <div v-if="edit_enable == false && communication_data.primary_email != '' && communication_data.primary_email_verified == true" class="">
                                            <i class="icon icon-check-circle fs-24 text-success ml-2"></i>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_enable && communication_data.primary_email_verified == false" style="position: absolute;right:19px;top:45px">
                                        <span class="fs-13 text-right d-block">{{(communication_data.primary_email).length}}/30</span>
                                    </div>
                                    <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                            placement="top"> -->
                                    <!-- <input name="primary_email" class="input-field-underline" v-validate="'required|email'"
                                        @focus="editValue('primary_email')" style="padding-top: 6px !important;"
                                        :readonly="true"
                                        v-model="communication_data.primary_email"> -->
                                        <input name="primary_email" class="input-field-underline" v-validate="'email'"
                                        style="padding-top: 6px !important;" maxlength="30"
                                        :readonly="communication_data.primary_email_verified == true"
                                        v-model="communication_data.primary_email" :class="{readonly : !edit_enable}">
                                    <!-- </el-tooltip> -->
                                    <span class="validation" v-show="errors.has('primary_email')">
                                        {{errors.first('primary_email')}}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="infoHeader">
                            <div class="mt-2" style="position: relative;" v-for="(email, emailindex) in communication_data.alternate_emails"
                                :key="'email'+emailindex">
                                <div class="d-flex align-items-center mt-6 new-contactView">
                                    <div v-if="communication_data.alternate_emails.length == 1" class="text-secondary profile-content-left-text profile-content-left-text-communication">Alternate Email</div>
                                    <div v-else class="text-secondary profile-content-left-text profile-content-left-text-communication">Alternate Email - {{emailindex+1}}</div>
                                    <div class="w-100">
                                        <div>
                                            <!-- <div v-if="edit_enable == true && alternate_email_index == emailindex" class="d-flex justify-content-end align-items-center new-action-btns"
                                                style="position: absolute;top:8px;right:69px;">
                                                <div class="pointer" @click="resetValue('alternate_email',emailindex)">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                </div>
                                                <div class="ml-2 pointer" @click="addUpdateCommunicationData('alternate_email', emailindex)">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                </div>
                                            </div> -->
                                            <div v-if="edit_enable == true" class="d-flex justify-content-end align-items-center new-action-btns" style="position: absolute;right:0px;top:42px">
                                                <span class="fs-13 text-right d-block">{{(email.email).length}}/30</span>
                                            </div>
                                            <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                            placement="top"> -->
                                            <div class="position-relative">
                                                <input class="input-field-underline" :readonly="!edit_enable" v-validate="'email'" :id="'alternate_email_input'+emailindex" data-vv-as="Alternate Email" maxlength="30" :name="'alternate_email_input'+emailindex"
                                                style="padding-top: 6px !important;"
                                                v-model="email.email" :class="{readonly : !edit_enable}"/>
                                                <!-- </el-tooltip> -->
                                                <div v-if="edit_enable == true" class="d-flex justify-content-end align-items-center new-action-btns"
                                                    style="position: absolute;top:8px;right:3px;">
                                                    <div v-if="emailindex > 0" class="pointer" @click="removeAltEmailCell(emailindex)">
                                                        <i class="icon icon-minus-circle text-danger" style="font-size: 19px !important;"></i>
                                                    </div>
                                                    <div v-if="emailindex == (communication_data.alternate_emails.length-1)" class="pointer" @click="addAltEmailCell()">
                                                        <i class="icon-plus-circle ml-1 ml-0" style="color: #4CAF50 !important; font-size: 18px;"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                                v-show="errors.has('alternate_email_input'+emailindex)">{{errors.first('alternate_email_input'+emailindex)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6" :class="{'pb-2': phoneindex === 0 }" v-for="(phone, phoneindex) in communication_data.phone_details"
                            :key="'phone'+phoneindex">
                            <div v-if="phone.is_primary == true" class="d-flex align-items-center new-contactView pt-2" style="display:flex;align-items:center">
                                <div class="text-secondary profile-content-left-text profile-content-left-text-communication">
                                    Primary Contact Number
                                </div>
                                <div class="w-100">
                                    <div class="profile-primay-phone" style="position: relative;">
                                        <div >
                                            <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                                placement="top"> -->
                                                <vue-tel-input
                                                    :disabled="phone.primary_phone_verified ? true : !edit_enable"
                                                    :enabledCountryCode="false"
                                                    :defaultCountry="communication_data.phone_details[phoneindex].country_code"
                                                    :name="'phone'+phoneindex"
                                                    :disabledFormatting="false" :validCharactersOnly="true"
                                                    @onInput="primaryPhoneNumber($event, phoneindex)"
                                                    :maxLen="maxPhoneLength" class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                    placeholder="Enter your Phone Number" v-validate="{min:10}"
                                                    data-vv-as="Primary Phone" style="padding-bottom: 0px !important;"
                                                    v-model="communication_data.phone_details[phoneindex].phone_number">
                                                </vue-tel-input>
                                            <!-- </el-tooltip> -->
                                        </div>
                                        <!-- <div v-if="edit_enable == true && phone.primary_phone_verified == false" class="d-flex justify-content-end align-items-center new-action-btns pointer" style="position: absolute;bottom:0px;right: 0">
                                            <div v-if="phone.primary_phone_verified == false" class="pointer" @click="resetValue('phone_number', phoneindex)">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                            </div>
                                            <div v-if="phone.primary_phone_verified == false" @click="addUpdateCommunicationData('primary_phone', phoneindex)" class="ml-2 pointer">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                            </div>
                                        </div> -->
                                        <div v-if="edit_enable == true && communication_data.phone_details[phoneindex].phone_number != '' && phone.primary_phone_verified == false" class="d-flex justify-content-end align-items-center new-action-btns pointer" style="position: absolute;right: 0">
                                            <div class="pointer" @click="sendOtpForPhoneEmail('primary_phone_verify')">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/verify-phone.svg"
                                                        width="14px">
                                            </div>
                                        </div>
                                        <div class="new-action-btns" style="position: absolute;right: 0; bottom: 0;" v-if="communication_data.phone_details[phoneindex].phone_number != '' && phone.primary_phone_verified == true">
                                            <i class="icon icon-check-circle fs-24 text-success"></i>
                                        </div>
                                        <!-- <div v-if="primary_phone_valid" style="position: absolute;">
                                            <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12">
                                                Primary Phone number must be at least 10 characters.
                                            </span>
                                        </div> -->
                                        <div style="position: absolute;">
                                            <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                            v-show="errors.has('phone'+phoneindex)">{{errors.first('phone'+phoneindex)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3" v-for="(phone,altphoneindex) in communication_data.phone_details"
                            :key="'altphone'+altphoneindex">
                            <div v-if="phone.is_primary == false" class="d-flex align-items-center new-contactView" style="align-items:center;padding-bottom: 12px !important;">
                                <div v-if="altphoneindex ==1" class="text-secondary profile-content-left-text profile-content-left-text-communication">
                                    Alternate Contact Number
                                </div>
                                <div :style="{marginLeft: altphoneindex>1 ? '230px !important' : '0 !important', marginBottom: altphoneindex>1 ? '10px !important' : '0 !important'}" class="leftType ml-0 profile-content-left-text mt-1">
                                    <div class="d-flex w-160px" :class="{readonly : !edit_enable}">
                                        <span style="border-bottom: 1px solid #ccc;padding-bottom: 0px !important;">
                                        <img v-if="communication_data.phone_details[altphoneindex].phone_type == 'Fax'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Home'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Line'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Messenger'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Mobile'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Telegram'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Whatsapp'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Work'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else-if="communication_data.phone_details[altphoneindex].phone_type == 'Others'"
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        <img v-else
                                            src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg"
                                            style="margin-right: 10px;margin-bottom: -15px !important;" />
                                        </span>
                                        <multiselect v-validate="'required'" v-model="communication_data.phone_details[altphoneindex].phone_type"
                                            class="diginew-multiselect diginew-multiselect-underline pointer alt-number" id="ajax"
                                            :options="alternate_contact_options"
                                            :loading="phone.is_data_loading"
                                            placeholder="Select"
                                            :multiple="false" :searchable="true" :internal-search="true"
                                            @open="getDropdownsDataByType('alternate_contact_number', altphoneindex)"
                                            :disabled="!edit_enable"
                                            :show-labels="false"
                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                            :max-height="200" :show-no-results="true" :hide-selected="false" >
                                            <template slot="option" slot-scope="{ option }">
                                                <div class="d-flex flex-row">
                                                    <img v-if="option == 'Fax'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Home'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Line'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Messenger'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Mobile'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Telegram'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Whatsapp'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Work'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else-if="option == 'Others'"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <img v-else
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg"
                                                        style="margin-right: 10px;" />
                                                    <span class="ml-2">
                                                        <span style="font-size: 12px">{{ option }}</span>
                                                    </span>
                                                </div>
                                            </template>
                                            <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListDropdown" />
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div :style="{marginBottom: altphoneindex>1 ? '10px !important' : '0 !important'}" class="profile-primay-phone ml-6 w-100" style="position: relative;">
                                    <div>
                                        <!-- <el-tooltip class="item contact-left" effect="dark" content="Click to edit"
                                            placement="top"> -->
                                            <!-- <vue-tel-input v-bind="bindProps" :disabled="!edit_enable"
                                                @onInput="altPhoneNumber($event, altphoneindex)" class="tele-contact-border-radius input-field-underline w-100"
                                                :name="'alternate_phone'+altphoneindex" v-model="communication_data.phone_details[altphoneindex].phone_number"
                                                v-validate="{min:10}" :valid-characters-only="true"
                                                :maxLen="15" placeholder="Enter your alternate Phone Number"
                                                :disabledFormatting="false" style="padding-bottom: 0px !important;"></vue-tel-input> -->
                                            <vue-tel-input
                                                :name="'alternate_phone'+altphoneindex"
                                                :disabled="!edit_enable" :enabledCountryCode="false"
                                                :disabledFormatting="false" :validCharactersOnly="true" :maxLen="maxPhoneLength"
                                                class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                @onInput="altPhoneNumber($event, altphoneindex)"
                                                :defaultCountry="communication_data.phone_details[altphoneindex].country_code"
                                                placeholder="Enter your alternate Phone Number"
                                                data-vv-as="Alternate Phone"
                                                v-validate="{min:10}" style="padding-bottom: 0px !important;"
                                                v-model="communication_data.phone_details[altphoneindex].phone_number">
                                            </vue-tel-input>
                                        <!-- </el-tooltip> -->
                                    </div>
                                    <div class="d-flex justify-content-end align-items-center new-action-btns pointer"
                                        style="position: absolute;right: 0px;margin-bottom: -7px;">
                                        <div v-if="communication_data.phone_details[altphoneindex].phone_type == 'Whatsapp' && edit_enable == false">
                                            <img class="ml-2"
                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Whatsapp.svg"
                                                style="height:19px">
                                        </div>
                                        <div v-if="communication_data.phone_details[altphoneindex].phone_type == 'Line' && edit_enable == false">
                                            <img class="ml-2"
                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line.svg"
                                                style="height:19px">
                                        </div>
                                        <div v-if="(communication_data.phone_details[altphoneindex].phone_type == 'Work' || communication_data.phone_details[altphoneindex].phone_type == 'Home' || communication_data.phone_details[altphoneindex].phone_type == 'Mobile') && edit_enable == false">
                                            <img class="ml-2"
                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/message.svg">
                                        </div>
                                        <div v-if="(communication_data.phone_details[altphoneindex].phone_type == 'Work' || communication_data.phone_details[altphoneindex].phone_type == 'Home' || communication_data.phone_details[altphoneindex].phone_type == 'Mobile') && edit_enable == false">
                                            <img class="ml-2"
                                                src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/phone.svg">
                                        </div>
                                        <!-- <div v-if="edit_enable == true" @click="resetValue('alternate_contact_number', altphoneindex)">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                        </div>
                                        <div v-if="edit_enable == true" @click="addUpdateCommunicationData('alternate_contact_number', altphoneindex)" class="ml-2 pointer">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                        </div> -->
                                        <div v-if="edit_enable == true && (communication_data.phone_details.length > 2)" class="d-flex align-items-center ml-5" style="margin-right:0px;margin-top:1px;" @click="deletePhoneNumber(phone, altphoneindex)">
                                            <i class="icon icon-minus-circle text-danger" style="font-size: 19px !important;"></i>
                                        </div>
                                        <div v-if="edit_enable == true && (altphoneindex == (communication_data.phone_details.length - 1))" class="d-flex align-items-center" @click="addPhoneNumber(altphoneindex)">
                                            <i class="icon-plus-circle" style="color: #4CAF50 !important;font-size: 18px;"></i>
                                        </div>
                                    </div>
                                    <!-- <div v-if="alt_phone_valid && alt_phone_index == altphoneindex" style="position: absolute;">
                                        <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12">
                                            Alternate Phone number must be at least 10 characters.
                                        </span>
                                    </div> -->
                                    <div style="position: absolute;">
                                        <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                        v-show="errors.has('alternate_phone'+altphoneindex)">{{errors.first('alternate_phone'+altphoneindex)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="infoHeader pb-4 contact-communication-emregency-contact" style="position: relative;">
                        <div class="">
                            <div class="d-flex align-items-center new-contactView">
                                <div class="text-secondary profile-content-left-text profile-content-left-text-communication fs-14 fw-600 mb-3">Emergency Contact</div>
                            </div>
                        </div>
                        <div class="overflow">
                            <!-- <div class="d-flex align-items-center" style="overflow: auto;"> -->
                            <div class="d-flex align-items-center">
                                <table class="table table-noborder">
                                    <thead>
                                        <tr>
                                            <!-- <th width="5%"> 
                                                <input v-model="emergency_contact_checkbox_all" type="checkbox" name="checked_all" id="checked_all" />
                                            </th> -->
                                            <th>Contact Name</th>
                                            <th>Contact Number</th>
                                            <th>Relationship</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(emergencyContact,emergcontindex) in  communication_data.emergency_contact" :key="'emergeCont'+emergcontindex">
                                            <!-- <td>
                                                <input type="checkbox" :checked="emergency_contact_checkbox_all" @change="contactChecked" :value="emergencyContact.id" :name="'checkbox'+emergcontindex" :id="'checkbox'+emergcontindex" />
                                            </td> -->
                                            <td style="vertical-align: top; padding: 1.5rem 3rem 2.2rem 3rem !important;">
                                                <div class="" style="position: relative;">
                                                    <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input class="input-field-underline"
                                                            style="padding-top: 11px !important;padding-bottom: 5px;width:160px;background-color: transparent !important; background: transparent !important; border-bottom: solid 1px #a9aaad !important;"
                                                            :readonly="!edit_enable" placeholder="Enter Contact Name"
                                                            v-validate="{required:true, alpha_spaces:true}"
                                                            data-vv-as="Contact Name" maxlength="50"
                                                            :name="'emergency_contact_name'+emergcontindex"
                                                            v-model="communication_data.emergency_contact[emergcontindex].contact_name" :class="{readonly : !edit_enable}">
                                                    <!-- </el-tooltip> -->
                                                    <!-- <div v-if="edit_enable == true" class="d-flex pl-2 align-items-center">
                                                        <div class="pointer" @click="resetValue('emergency_contact_name', emergcontindex)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addUpdateCommunicationData('emergency_contact_name', emergcontindex)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div >
                                                        <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                                        v-if="errors.has('emergency_contact_name'+emergcontindex)">
                                                        The Contact Name field may <br>only contain alphabetic <br>characters as well as spaces
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td  style="vertical-align: top; padding: 1.5rem 1rem 2.2rem 3rem !important;">
                                                <div class="d-flex align-items-center" style="position: relative;">
                                                    <div class="d-flex " >
                                                        <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                                            placement="top"> -->
                                                            <vue-tel-input v-bind="bindProps" :disabled="!edit_enable"
                                                            @onInput="emergencyPhoneNumber($event, emergcontindex)" class="tele-contact-border-radius input-field-underline tele-emergency-contact" 
                                                            :name="'emergency_number'+emergcontindex" ref="telInput" v-model="communication_data.emergency_contact[emergcontindex].contact_number.phone_number"
                                                            v-validate="{min:10}" :valid-characters-only="true"
                                                            :maxLen="15" placeholder="Enter your emergency phone number"
                                                            :disabledFormatting="false" style="padding-bottom: 0px !important; width:320px !important;"></vue-tel-input>
                                                            <!-- <vue-tel-input
                                                            @onInput="emergencyPhoneNumber($event, emergcontindex)"
                                                            :disabled="!edit_enable" :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="maxPhoneLength"
                                                            class="tele-contact-border-radius input-field-underline tele-emergency-contact" :name="'emergency_number'+emergcontindex" ref="telInput"
                                                            placeholder="Enter your emergency phone number"
                                                            :defaultCountry="communication_data.emergency_contact[emergcontindex].contact_number.country_code"
                                                            style="width: 300px !important;padding-bottom: 0px !important;"
                                                            data-vv-as="Emergency Number"
                                                            v-validate="{min:10}"
                                                            v-model="communication_data.emergency_contact[emergcontindex].contact_number.phone_number"></vue-tel-input> -->
                                                        <!-- </el-tooltip> -->
                                                        <img class="ml-2"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/message.svg">
                                                    <img class="ml-2"
                                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/phone.svg">
                                                    </div>
                                                   
                                                    <!-- <div v-if="edit_enable" class="d-flex align-items-center">
                                                        <div class="d-flex pl-2 align-items-center">
                                                            <div class="pointer" @click="resetValue('emergency_number', emergcontindex)">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="addUpdateCommunicationData('emergency_number', emergcontindex)">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div style="position: absolute;bottom: -20px;">
                                                        <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                                        v-if="errors.has('emergency_number'+emergcontindex)">The emergency number field must be at least 10 characters</span>
                                                        <!-- {{errors.first('emergency_number'+emergcontindex)}} -->
                                                    </div>
                                                </div>
                                            </td>
                                            <td  style="vertical-align: top; padding: 1.5rem 3rem 2.2rem 3rem !important;">
                                                <div class="d-flex align-items-center mt-1" style="position: relative;">
                                                    <!-- <el-tooltip class="item" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <multiselect v-model="communication_data.emergency_contact[emergcontindex].contact_relationship"
                                                            @open="getDropdownsDataByType('relationship', emergcontindex)"
                                                            :disabled="!edit_enable"
                                                            class="diginew-multiselect diginew-multiselect-underline pointer multiselect-emergency-contact" id="ajax"
                                                            :options="emergency_relationship_options"
                                                            :loading="emergencyContact.is_data_loading"
                                                            style="width: 135px !important;padding-bottom: 2px !important;height:32px !important"
                                                            :multiple="false" :searchable="true" :internal-search="true"
                                                            :show-labels="false"
                                                            v-validate="'required'"
                                                            :name="'emergency_relationship'+emergcontindex"
                                                            data-vv-as="Relationship"
                                                            :clear-on-select="false" :close-on-select="true"
                                                            :options-limit="280" :max-height="200" :show-no-results="true"
                                                            :hide-selected="false" :class="{readonly : !edit_enable}">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">No Realtion Types Found</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                            <template slot="afterList">
                                                                <div v-observe-visibility="reachedEndOfListDropdown" />
                                                            </template>
                                                        </multiselect>
                                                    <!-- </el-tooltip> -->
                                                    <!-- <div v-if="edit_enable" class="d-flex pl-2 align-items-center">
                                                        <div class="pointer" @click="resetValue('emergency_relationship', emergcontindex)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="addUpdateCommunicationData('emergency_relationship', emergcontindex)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    
                                                    <div style="position: absolute;bottom: -20px;">
                                                        <span class="invalid-feedback-form text-danger text-left d-block mt-0 fs-12"
                                                        v-show="errors.has('emergency_relationship'+emergcontindex)">{{errors.first('emergency_relationship'+emergcontindex)}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td  style="vertical-align: top; padding: 1.5rem 3rem 2.2rem 3rem !important;">
                                                <div class="d-flex align-items-center ml-7 w-100">
                                                    <div class="w-100 p-2" style="position: relative;">
                                                        <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                            style="position: absolute;top:18px;right:10px">
                                                            <div v-if="edit_enable == true" class="d-flex align-items-center" @click="deleteEmergencyCon(emergencyContact,emergcontindex)">
                                                                <i class="icon icon-minus-circle text-danger pointer" style="font-size: 19px !important;"></i>
                                                            </div>
                                                            <div v-if="edit_enable == true && (emergcontindex == (communication_data.emergency_contact.length - 1))" class="d-flex align-items-center" @click="addPeriod">
                                                                <i class="icon-plus-circle pointer" style="color: #4CAF50 !important;font-size: 18px;"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <!-- <div v-if="communication_data.emergency_contact.length == emergcontindex+1 && edit_enable" style="position: absolute; right: 0; bottom: -45px;">
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div v-if="emergcontindex > 0 && checkedCheckbox.length <= 0" class="pointer">
                                                        <button class="btn btn-danger" :disabled="!emergencyContact.delete_flag" @click="deletePeriod(emergcontindex)">-Delete Contact</button>
                                                    </div>
                                                    <div v-if="checkedCheckbox.length > 0" class="pointer">
                                                        <button class="btn btn-danger" @click="deleteEmergencyCon">-Delete Contacts</button>
                                                    </div>
                                                    <div class="ml-2 pointer">
                                                        <button class="btn btn-secondary" @click="addPeriod">+Add Contact</button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="infoHeader mt-3 pb-4" :class="{'mt-13': edit_enable, 'mt-4':!edit_enable}">
                        <div class="" v-for="(media, socialindex) in communication_data.social_media"
                            :key="'media'+socialindex">
                            <!-- <div v-if="socialindex == 0 && !edit_enable" class="mt-10 no-social mb-20 col-lg-12 col-md-12 col-sm-12" style="text-align: center !important;">
                                <span style="color: #00448b;font-size:14px;font-weight: 600;">No Social Media Added!
                                </span>
                            </div> -->
                            <div class="d-flex align-items-center new-contactView align-items-center">
                                <div class="text-secondary profile-content-left-text profile-content-left-text-communication fs-14" :class="{'v-hidden':socialindex > 0}">
                                    Social Media
                                    <sup class="fs-14 ml-1 mandatory">
                                        <el-tooltip class="item" effect="dark"
                                            content="Please enter full social media url."
                                            placement="right">
                                            <i class="icon-information text-secondary" style="font-size: 14px"></i>
                                        </el-tooltip>
                                    </sup>
                                </div>
                                
                                <div class="d-flex align-items-center profile-content-left-text profile-content-left-text-communication w-160px">
                                    <span style="border-bottom: 1px solid #ccc;margin-bottom: -8px;padding-bottom: 1px;">
                                    <img v-if="communication_data.social_media[socialindex].account_type.type === 'Facebook'"
                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg"
                                        style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;" />
                                    <img v-if="communication_data.social_media[socialindex].account_type.type === 'Instagram'"
                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/insta.svg"
                                        style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;" />
                                    <img v-if="communication_data.social_media[socialindex].account_type.type === 'Linkedin'"
                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/LinkedIn.svg"
                                        style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;" />
                                    <img v-if="communication_data.social_media[socialindex].account_type.type === 'Reddit'"
                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Reddit.svg"
                                        style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;" />
                                    <img v-if="communication_data.social_media[socialindex].account_type.type === 'Twitter'"
                                        src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/twitterr.svg"
                                        style="margin-right: 10px;width: 20px;padding-bottom: 2px !important;" />
                                    </span>
                                    <multiselect v-validate="'required'" v-model="communication_data.social_media[socialindex].account_type"
                                        class="diginew-multiselect diginew-multiselect-underline pointer" id="ajax"
                                        @open="getDropdownsDataByType('social_media', socialindex)"
                                        @close="validateSocialUrl(communication_data.social_media[socialindex].account_url, socialindex, communication_data.social_media[socialindex].account_type)"
                                        label="type"
                                        :disabled="!edit_enable"
                                        style="width: 150px !important;padding-bottom: 0px !important;height:32px !important"
                                        :loading="media.is_data_loading"
                                        openDirection='top'
                                        :options="social_media_type_options"
                                        :show-labels="false"
                                        :multiple="false" :searchable="true" :internal-search="true"
                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                        :max-height="200" :show-no-results="true" :hide-selected="false">
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">No Social Media Types Found</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                        <template slot="afterList">
                                                <div v-observe-visibility="reachedEndOfListDropdown" />
                                            </template>
                                    </multiselect>
                                </div>
                                <div class="d-flex align-items-center ml-2 w-100">
                                    <div class="w-100 p-2" style="position: relative;">
                                        <div class="position-relative">
                                            <input class="input-field-underline"
                                                style="padding-top: 6px !important"
                                                :readonly="!edit_enable"
                                                :name="'social_account_url'+socialindex"
                                                data-vv-as="Social URL"
                                                v-validate="'url'"
                                                @change="validateSocialUrl($event.target.value, socialindex, communication_data.social_media[socialindex].account_type)"
                                                v-model="communication_data.social_media[socialindex].account_url">
                                            <div v-if="edit_enable" class="d-flex justify-content-end align-items-center new-action-btns"
                                                style="position: absolute;top:18px;right:0px">
                                                <!-- socialindex>=0 -->
                                                <div v-if="communication_data.social_media.length > 1" class="d-flex align-items-center ml-5 pointer" @click="deleteSocialMedia(media.id, socialindex)">
                                                    <i class="icon icon-minus-circle text-danger" style="font-size: 19px !important;"></i>
                                                </div>
                                                <div v-if="communication_data.social_media.length == socialindex+1 && communication_data.social_media[socialindex].account_url != ''" class="d-flex align-items-center pointer" @click="addSocialMedia(socialindex)">
                                                    <i class="icon-plus-circle" style="color: #4CAF50 !important;font-size: 18px;"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="invalid-feedback-form font-weight-bold" style="margin-top: 4px !important;color: #ff3b3b; position: absolute;" v-show="errors.has('social_account_url'+socialindex)">{{ errors.first("social_account_url"+socialindex) }}</div> -->
                                        <div class="invalid-feedback-form font-weight-bold" v-if="communication_data.social_media[socialindex].is_url_invalid">
                                            Please enter complete and valid Social Url.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pb-8">
                    <button class="btn btn-outline-secondary" :disabled="upload_in_progress || save_api_call_in_progress" @click="editDisable" style="width: 120px;border-radius: 5px;">Cancel</button>
                    <button class="btn btn-new-success ml-5" :disabled="upload_in_progress || save_api_call_in_progress || fields_not_changed" @click="addUpdateCommunicationData" style="width: 120px;">Save
                            <hollow-dots-spinner v-if="upload_in_progress || save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                    </button>
                </div>
            </div>
        </div>
        <verify-phone v-if="showVerifyModal" modal_name="verify_phone" :emailPhoneVerify="emailPhoneVerify" @verfiy="verifyOtp"
            :phone="communication_data.phone_details[0].phone_number.replace(/^0+/, '')" :email="communication_data.primary_email" @close="showVerifyModal = false">
        </verify-phone>
        <verify-phone-success v-if="verify_phone_success" :emailPhoneVerify="emailPhoneVerify" @deactivateEditMode="deactivateEditMode" :phone="communication_data.phone_details[0].phone_number.replace(/^0+/, '')" :email="communication_data.primary_email" :login_id="loggedInUser.login_id" modal_name="verify_phone_success"></verify-phone-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import { HollowDotsSpinner } from "epic-spinners";
    import axios from 'axios';
    import VerifyPhone from "./VerifyPhone";
    import profile from "../mixins/profile";
    import { SweetModal } from 'sweet-modal-vue';
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import globals from '../globals';
    import VerifyPhoneSuccess from './VerifyPhoneSuccess.vue';
    export default {
        props: [],
        mixins: [profile],
        components: {
            HalfCircleSpinner,
            VerifyPhone,
            VerifyPhoneSuccess,
            SweetModal,
            HollowDotsSpinner
        },
        data() {
            return {
                bindProps: {
                    mode: "international",
                    defaultCountry: "US"
                },
                warning_msg: '',
                success_msg: '',
                ajax_call_in_progress: false,
                verify_phone_success: false,
                edit_primary_email: false,
                edit_alternate_email: false,
                edit_phone_number: false,
                edit_alternate_phone_number: false,
                edit_emergency_number: false,
                edit_emergency_relationship: false,
                edit_account_url: false,
                edit_emergency_contact_name: false,
                dropdown_loading: false,
                emergency_contact_name_index: 0,
                emergency_number_index: 0,
                relationship_index: 0,
                emergency_relationship_options: [],
                maxPhoneLength: 15,
                edit_communication_data: {},
                user_data: {},
                alternate_email_input: [{
                    email: ''
                }],
                alternate_email_index: 0,
                new_primary_phone: '',
                new_alternate_phone_number: '',
                alternate_phone_type: '',
                alternate_phone_type: 'Work',
                social_media_type: 'Facebook',
                emergency_contact_checkbox_all: false,
                emergency_contact_checkbox: false,
                social_media_url: '',
                // alternate_phone: {
                //     phone: '',
                //     phone_type:''
                // },
                communication_data: {
                    alternate_emails: [{
                        email: ''
                    }],
                    emergency_contact: [
                        {
                            contact_name: "",
                            contact_number: {
                                country_code: "US",
                                dial_code: "1",
                                phone_number: ""
                            },
                            contact_relationship: "",
                        },
                    ],
                    phone_details: [
                        {
                            country_code: "US",
                            dial_code: "1",
                            is_primary: true,
                            phone_number: "",
                            phone_type: "Primary",
                            primary_phone_verified: false,
                            is_data_loading: false
                        },
                        {
                            country_code: "US",
                            dial_code: "1",
                            is_primary: false,
                            phone_number: "",
                            phone_type: "Work",
                            primary_phone_verified: false,
                            is_data_loading: false
                        }
                    ],
                    primary_email: "",
                    primary_email_verified: false,
                    social_media: []
                },
                alternate_contact_options: [],
                showVerifyModal: false,
                social_media_type_options: [],
                emailPhoneVerify: '',
                checkedCheckbox: [],
                alternate_phone_index: 0,
                social_media_index: 0,
                is_alt_phone_valid: false,
                is_primary_phone_valid: false,
                alt_phone_valid: false,
                alt_phone_index: 0,
                emergency_phone_index: 0,
                primary_phone_valid: false,
                isVisible: false,
                scrollable: false,
                which_type: '',
                count: 0,
                skip: 0,
                limit: 10,
                social_media_api_call_index: 0,
                relationship_api_call_index: 0,
                alternate_contact_number_api_call_index: 0,
                edit_enable: false,
                upload_in_progress: false,
                save_api_call_in_progress: false,
                stored_user_data: null,
                stringified_response: "",
                // fb_url_regex: '/(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/',
                // insta_url_regex: '/?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/',
                // twitter_url_regex: '/(https:\/\/twitter.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+))/',
                // lnkd_url_regex: '/(^((https?:\/\/)?((www|\w\w)\.)?)linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/',
                // reddit_url_regex: '/?:https?:)?\/\/(?:[a-z]+\.)?reddit\.com\/(?:u(?:ser)?)\/(?P<username>[A-z0-9\-\_]*)\/?/',
            }
        },
        methods: {
            enableEdit() {
                this.edit_enable = true
                if(this.communication_data.social_media.length == 0){
                    addSocialMedia ('');
                }
            },
            editDisable() {
                this.edit_enable = false
                this.communication_data.primary_email = this.stored_user_data.primary_email;
                this.communication_data.primary_email_verified = this.stored_user_data.primary_email_verified;
                this.communication_data.alternate_emails = this.stored_user_data.alternate_emails;
                this.communication_data.emergency_contact = this.stored_user_data.emergency_contact;
                this.communication_data.phone_details = this.stored_user_data.phone_details;
                this.communication_data.phone_details['is_data_loading'] = false
                this.communication_data.emergency_contact['is_data_loading'] = false
                if(this.stored_user_data.alternate_emails.length == 0) {
                    this.communication_data.alternate_emails = [{
                        email: ''
                    }];
                } else {
                    this.stored_user_data.alternate_emails.forEach((value, index)=>{
                        this.communication_data.alternate_emails[index] = {
                            email: value
                        };
                    })
                }
                if(this.communication_data.emergency_contact.length == 0) {
                    this.communication_data.emergency_contact = [{
                        contact_name: "",
                        contact_number: {
                            country_code: "",
                            dial_code: "",
                            phone_number: ""
                        },
                        contact_relationship: "",
                        is_data_loading: false
                    }];
                }
                if(this.stored_user_data.social_media.length == 0) {
                    this.communication_data.social_media = [{
                        account_type: {
                            type: 'Facebook',
                            icon_url: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg'
                        },
                        account_url: "",
                        is_data_loading: false,
                        is_url_invalid: false
                    }];
                } else {
                    this.communication_data.social_media = [];
                    this.stored_user_data.social_media.forEach((el, index)=>{
                        this.communication_data.social_media.push({
                            account_type: {
                                type: el.account_type,
                                icon_url: el.icon_url
                            },
                            account_url: el.account_url,
                            id: el.id,
                            is_data_loading: false,
                            is_url_invalid: false
                        })
                    })
                }
                if(this.communication_data.phone_details.length == 0) {
                    this.communication_data.phone_details = [
                        {
                            country_code: "US",
                            dial_code: "1",
                            is_primary: true,
                            phone_number: "",
                            phone_type: "Primary",
                            primary_phone_verified: false,
                        },
                        {
                            country_code: "",
                            dial_code: "",
                            is_primary: false,
                            phone_number: "",
                            phone_type: "Work",
                            primary_phone_verified: false,
                            is_data_loading: false
                        }
                    ];
                }
                else {
                    if(this.communication_data.phone_details[0].is_primary == true && this.communication_data.phone_details.length == 1) {
                        this.communication_data.phone_details.push({
                            country_code: "US",
                            dial_code: "1",
                            is_primary: false,
                            phone_number: "",
                            phone_type: "Work",
                            primary_phone_verified: false,
                            is_data_loading: false
                        });
                    }
                    // else {
                    //     this.communication_data.phone_details.unshift({
                    //         country_code: "IN",
                    //         dial_code: "91",
                    //         is_primary: true,
                    //         phone_number: "",
                    //         phone_type: "Primary",
                    //         primary_phone_verified: false,
                    //     });
                    // }
                }
                this.errors.clear();
            },
            validateSocialUrl(value, index, account_type) {
                if(value) {
                    if(account_type.type == 'Facebook') {
                        let fb_url_regex = /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/
                        this.communication_data.social_media[index].is_url_invalid = !fb_url_regex.test(value);
                    } else if(account_type.type == 'Instagram') {
                        let ig_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(instagram.com|instagr.am|instagr.com)/
                        this.communication_data.social_media[index].is_url_invalid = !ig_url_regex.test(value);
                    } else if(account_type.type == 'Twitter') {
                        let twtr_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(twitter.com)\/(\w+)/
                        this.communication_data.social_media[index].is_url_invalid = !twtr_url_regex.test(value);
                    } else if(account_type.type == 'Linkedin') {
                        let lnkn_url_regex = /(?:https?:\/\/)?(?:www\.)?(linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/
                        this.communication_data.social_media[index].is_url_invalid = !lnkn_url_regex.test(value);
                    } else if(account_type.type == 'Reddit') {
                        let redt_url_regex = /(?:http|https?:\/\/)?(?:www\.)?(reddit.com)\/(\w+)/
                        this.communication_data.social_media[index].is_url_invalid = !redt_url_regex.test(value);
                    }
                } else {
                    this.communication_data.social_media[index].is_url_invalid = false
                }
            },
            altPhoneNumber({number,isValid,country}, altphoneindex) {
                // let phone = [];
                // phone[0].number = number;
                // phone[0].isValid = isValid;
                // phone[0].country = country;
                // this.communication_data.phone_details[altphoneindex].phone_number = phone[0].number = number;
                // this.communication_data.phone_details[altphoneindex].dial_code = phone[0].country.dialCode;
                // this.communication_data.phone_details[altphoneindex].country_code = phone[0].country.iso2;
                this.communication_data.phone_details[altphoneindex].phone_number = number.national;
                this.communication_data.phone_details[altphoneindex].dial_code = country.dialCode;
                this.communication_data.phone_details[altphoneindex].country_code = country.iso2;
                // this.editValue('alternate_contact_number', altphoneindex);
                this.alt_phone_index = altphoneindex;
            },
            primaryPhoneNumber({number,isValid,country}, phoneindex) {
                let phone = [];
                phone[0].number = number;
                phone[0].isValid = isValid;
                phone[0].country = country;
                // this.editValue('phone_number', phoneindex)
                this.communication_data.phone_details[phoneindex].phone_number = phone[0].number = number;
                this.communication_data.phone_details[phoneindex].dial_code = phone[0].country.dialCode;
                this.communication_data.phone_details[phoneindex].country_code = phone[0].country.iso2;
            },
            emergencyPhoneNumber({number,isValid,country}, emergcontindex) {
                let phone = [];
                phone[0].number = number;
                phone[0].isValid = isValid;
                phone[0].country = country;
                // this.editValue('emergency_number', emergcontindex);
                this.emergency_phone_index = emergcontindex;
                this.communication_data.emergency_contact[emergcontindex].contact_number.phone_number = phone[0].number = number;
                this.communication_data.emergency_contact[emergcontindex].contact_number.dial_code = phone[0].country.dialCode;
                this.communication_data.emergency_contact[emergcontindex].contact_number.country_code = phone[0].country.iso2;
                // this.communication_data.emergency_contact[emergcontindex].contact_number.phone_number = number.national;
                // this.communication_data.emergency_contact[emergcontindex].contact_number.dial_code = country.dialCode;
                // this.communication_data.emergency_contact[emergcontindex].contact_number.country_code = country.iso2;
            },
            async sendOtpForPhoneEmail(otpfor) {
                this.emailPhoneVerify = otpfor;
                let res = await this.$http.post(globals.AUTH_SERVICE + "/auth/otp", {
                    login_id: otpfor == 'primary_phone_verify' ? this.communication_data.phone_details[0].phone_number.replace(/^0+/, '') : this.communication_data.primary_email,
                    otp_for: otpfor,
                    user_id: this.loggedInUser.user_id
                });
                if (res.data.status_id == 1) {
                    this.showVerifyModal = true;
                    setTimeout(() => {
                        this.$modal.show("verify_phone");
                    }, 500);
                }
            },
            async verifyOtp() {
                this.showVerifyModal = false;
                this.verify_phone_success = true;
                setTimeout(() => {
                    this.$modal.show("verify_phone_success");
                }, 500);
            },
            deactivateEditMode() {
                this.verify_phone_success = false;
                this.getCommunicationData();
            },
            showAlternateEmmailEdit(ind) {
                this.alternate_email_index = ind;
                this.edit_alternate_email = true;
                setTimeout(() => {
                    $("#alternate_email_input"+ind).focus();
                }, 500);
            },
            addPhoneNumber() {
                this.communication_data.phone_details.push(
                    {
                        country_code: "US",
                        dial_code: "1",
                        is_primary: false,
                        phone_number: "",
                        phone_type: "Work",
                        primary_phone_verified: false,
                        is_data_loading: false
                    }
                );
            },
            async deletePhoneNumber(data, index) {
                if(data.id) {
                    try {
                        let response = await this.deleteCommunicationPhoneById(data.id);
                        if(response.status_id == 1) {
                            this.getCommunicationData();
                        }
                    }
                    catch(err) {
                    }
                } else {
                    this.communication_data.phone_details.splice(index,1);
                }
            },
            addAltEmailCell() {
                this.communication_data.alternate_emails.push({
                    email: ''
                });
            },
            removeAltEmailCell(index) {
                this.communication_data.alternate_emails.splice(index,1);
                // this.addUpdateCommunicationData();
            },
            addSocialMedia(index) {
                this.communication_data.social_media.push(
                    {
                        account_type: {
                            type: 'Facebook',
                            icon_url: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg'
                        },
                        account_url: '',
                        is_data_loading: false,
                        is_url_invalid: false
                    }
                );
            },
            editUser(obj, index) {
                if (obj == 'alternate_email') {
                    this.alternate_email_index = index;
                    this.edit_alternate_email = true;
                    // setTimeout(() => {
                    //     $("#alternate_email_input"+index).focus();
                    // }, 500);
                }
            },
            addPeriod(){
                this.communication_data.emergency_contact.push(
                    {
                        contact_name: "",
                        contact_number: {
                            country_code: "US",
                            dial_code: "1",
                            phone_number: ""
                        },
                        contact_relationship: "",
                        delete_flag: true
                    }
                );
            },
            contactChecked(data){
                if(data.target.checked) {
                    if(data.target.value != '') {
                        this.checkedCheckbox.push(data.target.value);
                    }
                } else {
                    const index = this.checkedCheckbox.indexOf(data.target.value);
                    if (index > -1) {
                        this.checkedCheckbox.splice(index, 1);
                    }
                }
            },
            deletePeriod(index){
                if(this.communication_data.emergency_contact[index].id === '' || this.communication_data.emergency_contact[index].id === undefined || this.communication_data.emergency_contact[index].id === null) {
                    this.communication_data.emergency_contact.splice(index,1);
                    this.emergency_contact_checkbox_all = false;
                    this.emergency_contact_checkbox = false;
                }
            },
            async deleteEmergencyCon(data, index){
                if(this.communication_data.emergency_contact.length > 1) {
                    this.communication_data.emergency_contact.splice(index,1);
                } else {
                    this.communication_data.emergency_contact[index] = {
                        contact_name: "",
                        contact_number: {
                            country_code: "IN",
                            dial_code: "91",
                            phone_number: ""
                        },
                        contact_relationship: "",
                    }
                }
                this.$forceUpdate()
                // try {
                //     let params = {
                //         id: this.checkedCheckbox
                //     }
                //     let response = await this.deleteCommunicationEmergencyContactById(params);
                //     if(response.status_id == 1) {
                //         this.getCommunicationData();
                //     }
                // }
                // catch(err) {

                // }
            },
            async getCommunicationData() {
                this.ajax_call_in_progress = true;
                try {
                    let param = this.$route.params.id ? this.$route.params.id : this.loggedInUser.user_id;
                    let response = await this.getCommunication(param);
                    if(response.status_id == 1) {
                        localStorage.setItem('comm_data', JSON.stringify(response.response));
                        this.edit_primary_email = false;
                        this.edit_alternate_email = false;
                        this.edit_phone_number = false;
                        this.edit_alternate_phone_number = false;
                        this.edit_emergency_number = false;
                        this.edit_emergency_relationship = false;
                        this.edit_account_url = false;
                        this.edit_emergency_contact_name = false;
                        this.emergency_contact_checkbox_all = false;
                        this.emergency_contact_checkbox = false;
                        this.user_data = response.response;
                        this.stored_user_data = JSON.parse(JSON.stringify(response.response))
                        this.communication_data.primary_email = this.user_data.primary_email;
                        this.communication_data.primary_email_verified = this.user_data.primary_email_verified;
                        this.communication_data.alternate_emails = this.user_data.alternate_emails;
                        this.communication_data.emergency_contact = this.user_data.emergency_contact;
                        this.communication_data.phone_details = this.user_data.phone_details;
                        this.communication_data.phone_details['is_data_loading'] = false
                        this.communication_data.emergency_contact['is_data_loading'] = false
                        // this.communication_data.social_media = this.user_data.social_media;

                        if(this.user_data.alternate_emails.length == 0) {
                            this.communication_data.alternate_emails = [{
                                email: ''
                            }];
                        } else {
                            this.user_data.alternate_emails.forEach((value, index)=>{
                                this.communication_data.alternate_emails[index] = {
                                    email: value
                                };
                            })
                        }
                        if(this.communication_data.emergency_contact.length == 0) {
                            this.communication_data.emergency_contact = [{
                                contact_name: "",
                                contact_number: {
                                    country_code: "",
                                    dial_code: "",
                                    phone_number: ""
                                },
                                contact_relationship: "",
                                is_data_loading: false
                            }];
                        }
                        if(this.user_data.social_media.length == 0) {
                            this.communication_data.social_media = [{
                                account_type: {
                                    type: 'Facebook',
                                    icon_url: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/Facebook.svg'
                                },
                                account_url: "",
                                is_data_loading: false,
                                is_url_invalid: false
                            }];
                        } else {
                            this.communication_data.social_media = [];
                            this.user_data.social_media.forEach((el, index)=>{
                                this.communication_data.social_media.push({
                                    account_type: {
                                        type: el.account_type,
                                        icon_url: el.icon_url
                                    },
                                    account_url: el.account_url,
                                    id: el.id,
                                    is_data_loading: false,
                                    is_url_invalid: false
                                })
                            })
                        }
                        if(this.communication_data.phone_details.length == 0) {
                            this.communication_data.phone_details = [
                                {
                                    country_code: "US",
                                    dial_code: "1",
                                    is_primary: true,
                                    phone_number: "",
                                    phone_type: "Primary",
                                    primary_phone_verified: false,
                                },
                                {
                                    country_code: "",
                                    dial_code: "",
                                    is_primary: false,
                                    phone_number: "",
                                    phone_type: "Work",
                                    primary_phone_verified: false,
                                    is_data_loading: false
                                }
                            ];
                        }
                        else {
                            if(this.communication_data.phone_details[0].is_primary == true && this.communication_data.phone_details.length == 1) {
                                this.communication_data.phone_details.push({
                                    country_code: "US",
                                    dial_code: "1",
                                    is_primary: false,
                                    phone_number: "",
                                    phone_type: "Work",
                                    primary_phone_verified: false,
                                    is_data_loading: false
                                });
                            }
                            // else {
                            //     this.communication_data.phone_details.unshift({
                            //         country_code: "IN",
                            //         dial_code: "91",
                            //         is_primary: true,
                            //         phone_number: "",
                            //         phone_type: "Primary",
                            //         primary_phone_verified: false,
                            //     });
                            // }
                        }
                        this.ajax_call_in_progress = false;
                        this.stringified_response = JSON.stringify(this.create_data)
                    }
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async addUpdateCommunicationData(data, index) {
                this.save_api_call_in_progress = true;
                try {
                    let params = {
                        alternate_emails: [],
                        phone_details: [],
                        social_media: [],
                        emergency_contact: []
                    }
                    let emergency_contact_details_duplicate = false
                    // params.primary_email = this.communication_data.primary_email;
                    this.communication_data.alternate_emails.forEach((value, index)=>{
                        params.alternate_emails.push(value.email);
                    })
                    params.alternate_emails = params.alternate_emails.filter(item => item);
                    this.communication_data.phone_details.forEach((value, index)=>{
                        if(value.id) {
                            params.phone_details.push({
                                phone_type: value.phone_type,
                                phone_number: value.phone_number,
                                country_code: value.country_code,
                                dial_code: value.dial_code,
                                primary_phone_verified: false,
                                is_primary: value.is_primary,
                                user_id: this.loggedInUser.user_id,
                                id: value.id
                            })
                        } else {
                            params.phone_details.push({
                                phone_type: value.phone_type,
                                phone_number: value.phone_number,
                                country_code: value.country_code,
                                dial_code: value.dial_code,
                                primary_phone_verified: false,
                                is_primary: value.is_primary,
                                user_id: this.loggedInUser.user_id,
                            })
                        }
                    })
                    let is_social_url_invalid = false
                    this.communication_data.social_media.forEach((value, index)=>{
                        if(value.is_url_invalid) {
                            is_social_url_invalid = true
                        }
                        if(value.id) {
                            params.social_media.push({
                                account_type: value.account_type.type,
                                account_url: value.account_url,
                                icon_url: value.account_type.icon_url,
                                id: value.id,
                                user_id: this.loggedInUser.user_id,
                            })
                        } else {
                            params.social_media.push({
                                account_type: value.account_type.type,
                                icon_url: value.account_type.icon_url,
                                account_url: value.account_url,
                            })
                        }
                    })
                    if(emergency_contact_details_duplicate == false) {
                        var valueContactName = this.communication_data.emergency_contact.map((item) => { return item.contact_name });
                        emergency_contact_details_duplicate = valueContactName.some((item, idx) => { 
                            return valueContactName.indexOf(item) != idx 
                        });
                    }
                    if(emergency_contact_details_duplicate == false) {
                        var valuecontact_number = this.communication_data.emergency_contact.map((item) => { return item.contact_number });
                        emergency_contact_details_duplicate = valuecontact_number.some((item, idx) => { 
                            return valuecontact_number.indexOf(item) != idx 
                        });
                    }
                    if(emergency_contact_details_duplicate == false) {
                        var valuecontact_relationship = this.communication_data.emergency_contact.map((item) => { return item.contact_relationship });
                        emergency_contact_details_duplicate = valuecontact_relationship.some((item, idx) => { 
                            return valuecontact_relationship.indexOf(item) != idx 
                        });
                    }
                    this.communication_data.emergency_contact.forEach((value, index)=>{
                        if(value.id) {
                            params.emergency_contact.push({
                                contact_name: value.contact_name,
                                contact_number: value.contact_number,
                                contact_relationship: value.contact_relationship,
                                id: value.id,
                            })
                        } else {
                            params.emergency_contact.push({
                                contact_name: value.contact_name,
                                contact_number: value.contact_number,
                                contact_relationship: value.contact_relationship,
                            })
                        }
                    })
                    if(is_social_url_invalid) {
                        this.warning_msg = 'Please enter valid social media Url.';
                        this.$refs.warning_modal.open();
                        setTimeout(() => {
                            this.$refs.warning_modal.close();
                        }, 3000);
                    } else {
                        if(emergency_contact_details_duplicate) {
                            this.warning_msg = 'Please remove duplicate entries in emergency contact details';
                            this.$refs.warning_modal.open();
                            setTimeout(() => {
                                this.$refs.warning_modal.close();
                            }, 3000);
                        } else {
                            this.$validator.validateAll().then(async result => {
                                if (result) {
                                    this.save_api_call_in_progress = true;
                                    await axios.put(globals.AUTH_SERVICE + `/profile/communication/${this.loggedInUser.user_id}`, params).then((response) => {
                                        if(response.data.status_id == 1) {
                                            this.success_msg = response.data.message
                                            this.$refs.success_modal.open();
                                            setTimeout(() => {
                                                this.$refs.success_modal.close();
                                                this.save_api_call_in_progress = false
                                                this.edit_enable =  false
                                                this.getCommunicationData();
                                                // this.$emit("updateSuccess");
                                            }, 2000);
                                        }
                                    }).catch((error) => {
                                        this.warning_msg = error.response.data.reason;
                                        this.$refs.warning_modal.open();
                                        this.save_api_call_in_progress = false
                                    });
                                    // let response = await this.addUpdateCommunication(params, this.loggedInUser.user_id);
                                    // if(response.status_id == 1) {
                                    //     this.success_msg = response.message
                                    //     this.$refs.success_modal.open();
                                    //     setTimeout(() => {
                                    //         this.$refs.success_modal.close();
                                    //         this.edit_enable =  false
                                    //         this.getCommunicationData();
                                    //     }, 3000);
                                    //     this.save_api_call_in_progress = false;
                                    // }else{
                                    //     console.log(":hi")
                                    //     console.log(response)
                                    //     this.warning_msg = response.reason
                                    //     this.save_api_call_in_progress = false;
                                    //     this.$refs.warning_modal.open();
                                    //     setTimeout(() => {
                                    //         this.$refs.warning_modal.close();
                                    //     }, 3000);
                                    // }
                                }
                                else {
                                    this.warning_msg = "Please fill the required fields";
                                    this.$refs.warning_modal.open();
                                    setTimeout(() => {
                                        this.$refs.warning_modal.close();
                                    }, 3000);
                                }
                            });
                        }
                    }
                }
                catch(err) {
                    this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                    setTimeout(() => {
                        this.$refs.warning_modal.close();
                        this.save_api_call_in_progress = false;
                    }, 3000);
                }
                this.save_api_call_in_progress = false;
            },
            async deleteSocialMedia(id, index) {
                if(id) {
                    try {
                        let param = id;
                        let response = await this.deleteCommunicationSocialMediaById(param);
                        if(response.status_id == 1) {
                            this.getCommunicationData();
                        }
                    }
                    catch(err) {
                        // console.log(err);
                    }
                } else {
                    this.communication_data.social_media.splice(index,1);
                }
            },
            async getDropdownsDataByType(type, index) {
                this.which_type = type;
                if(type == 'social_media') {
                    // this.editValue('account_url', index);
                    this.social_media_api_call_index = index
                    this.communication_data.social_media[index].is_data_loading = true;
                }
                else if(type == 'relationship') {
                    // this.editValue('emergency_relationship', index);
                    this.communication_data.emergency_contact[index].is_data_loading = true;
                    this.relationship_api_call_index = index
                } else if(type == 'alternate_contact_number') {
                    // this.editValue('alternate_contact_number', index);
                    this.communication_data.phone_details[index].is_data_loading = true;
                    this.alternate_contact_number_api_call_index = index
                }
                // this.dropdown_loading = true;
                try {
                    let params = {
                        type: type,
                        skip: this.skip,
                        limit: this.limit
                    };
                    let response = await this.getDropdownsData(params);
                    if(response.status_id == 1) {
                        if(type == 'social_media') {
                            this.social_media_type_options = response.response;
                        }
                        else if(type == 'relationship') {
                            this.emergency_relationship_options = response.response;
                        } else if(type == 'alternate_contact_number') {
                            this.alternate_contact_options = response.response;
                        }
                    }
                    // this.dropdown_loading = false;
                    if(type == 'social_media') {
                        this.communication_data.social_media[index].is_data_loading = false;
                    }
                    else if(type == 'relationship') {
                        this.communication_data.emergency_contact[index].is_data_loading = false;
                    } else if(type == 'alternate_contact_number') {
                        this.communication_data.phone_details[index].is_data_loading = false;
                    }
                    this.count = response.response.length;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async reachedEndOfListDropdown(reached) {
                this.isVisible = reached;
                if (reached) {
                    if (this.scrollable == false) {
                        // this.dropdown_loading = true;
                        if(this.which_type == 'social_media') {
                            this.communication_data.social_media[this.social_media_api_call_index].is_data_loading = true;
                        }
                        else if(this.which_type == 'relationship') {
                            this.communication_data.emergency_contact[this.relationship_api_call_index].is_data_loading = true;
                        }
                        else if(this.which_type == 'alternate_contact_number') {
                            this.communication_data.phone_details[this.alternate_contact_number_api_call_index].is_data_loading = true;
                        }
                        let params = {
                            limit: 10,
                            type: this.which_type,
                            skip: this.count
                        };
                        let response = await this.getDropdownsData(params);
                        this.temp_dropdown_options = response.response.length;;
                        if (response.skip == this.count) {
                            if (this.temp_dropdown_options > 0) {
                                let tempFiles = response.response;
                                if(this.which_type == 'social_media') {
                                    tempFiles.forEach(el => {
                                        this.social_media_type_options.push(el);
                                    });
                                }
                                else if(this.which_type == 'relationship') {
                                    tempFiles.forEach(el => {
                                        this.emergency_relationship_options.push(el);
                                    });
                                }
                                else if(this.which_type == 'alternate_contact_number') {
                                    tempFiles.forEach(el => {
                                        this.alternate_contact_options.push(el);
                                    });
                                }
                                this.count += response.response.length;
                                // this.dropdown_loading = false
                                if(this.which_type == 'social_media') {
                                    this.communication_data.social_media[this.social_media_api_call_index].is_data_loading = false;
                                }
                                else if(this.which_type == 'relationship') {
                                    this.communication_data.emergency_contact[this.relationship_api_call_index].is_data_loading = false;
                                }
                                else if(this.which_type == 'alternate_contact_number') {
                                    this.communication_data.phone_details[this.alternate_contact_number_api_call_index].is_data_loading = false;
                                }
                            } else {
                                this.scrollable = true
                                // this.dropdown_loading = false
                                if(this.which_type == 'social_media') {
                                    this.communication_data.social_media[this.social_media_api_call_index].is_data_loading = false;
                                }
                                else if(this.which_type == 'relationship') {
                                    this.communication_data.emergency_contact[this.relationship_api_call_index].is_data_loading = false;
                                }
                                else if(this.which_type == 'alternate_contact_number') {
                                    this.communication_data.phone_details[this.alternate_contact_number_api_call_index].is_data_loading = false;
                                }
                            }
                        }
                        // this.dropdown_loading = false
                        if(this.which_type == 'social_media') {
                            this.communication_data.social_media[this.social_media_api_call_index].is_data_loading = false;
                        }
                        else if(this.which_type == 'relationship') {
                            this.communication_data.emergency_contact[this.relationship_api_call_index].is_data_loading = false;
                        }
                        else if(this.which_type == 'alternate_contact_number') {
                            this.communication_data.phone_details[this.alternate_contact_number_api_call_index].is_data_loading = false;
                        }
                    }
                }
            },
        },
        mounted() {
            this.getCommunicationData();
        },
        beforeRouteLeave (to, from, next) {
            if (this.fields_not_changed == false) {
                let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        watch: {
            emergency_contact_checkbox_all: function (newval, oldval) {
                if(newval == true) {
                    this.checkedCheckbox = [];
                    this.communication_data.emergency_contact.forEach((contact,index)=>{
                        if(contact.id != undefined || contact.id != null) {
                            this.checkedCheckbox.push(contact.id);
                        }
                    })
                } else {
                    this.checkedCheckbox = [];
                }
            }
        },
        created() {
            const dict = {
                custom: {
                    alternate_email_input:{
                        email: () => "Please enter valid Email Address",
                    },
                    social_account_url: {
                        regex: () => "Enter valid Acoount URL."
                    },
                    phone: {
                        min: () => "Enter valid Phone Number."
                    },
                    alternate_phone: {
                        min: () => "Alternate Phone Number must contain more than 9 Numbers"
                    }
                }
            };
            this.$validator.localize("en", dict);
        },
        computed: {
            fields_not_changed(){
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            create_data() {
                return {
                    // "primary_email": this.communication_data.primary_email,
                    "primary_email_verified": this.communication_data.primary_email_verified,
                    "alternate_emails": this.communication_data.alternate_emails,
                    "emergency_contact": this.communication_data.emergency_contact,
                    "phone_details" : this.communication_data.phone_details,
                    "social_media" : this.communication_data.social_media
                }
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
        beforeDestroy() {
            localStorage.removeItem('comm_data');
        },
    };
</script>

<style>
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .validation {
        color: red
    }
    .teleContact{
        background-color: white !important;
    }
    .teleContact > input{
        background-color: white !important;
        width: 100%;
        padding-right: 50px;
    }
    .teleContact > input:hover{
        background-color: white !important;
    }
    .teleContact:hover,
    .teleContact {
        background-color: white !important;
    }
    .teleContact .dropdown:hover,
    .teleContact .dropdown {
        background-color: white !important;
    }
    .teleContact1 > input{
        width: 300px
    }
    .digi-multiselect{
        border-bottom: 0px;
    }
    .vue-tel-input > .dropdown{
        padding: 5px !important
    }

    /*------------ */

    .vue-tel-input{
        display:flex;
    }

    .tele-contact-border-radius .selection{
        display: flex !important;
    }

    .tele-contact-border-radius .selection ul{
        border: 1px solid rgb(0, 0, 0);
        max-height: 75px;
        overflow: auto;
        z-index: 1;
        background: rgb(255, 255, 255);
        position: absolute;
        top: 34px;
        left: -50px;
    }
    .w-160px {
        width: 160px;
    }
    .new-contactView{
        align-items: center;
    }
    @media only screen and (min-width: 1024px){
        .no-social{
            margin-top: 30px !important;
            border: 1px solid #ededed;
            padding: 20px;
            border-radius: 6px;
        }
        .edit-social{
            margin-top: -25px !important;
        }
    }
    @media only screen and (min-width: 0px) and (max-width: 350px){
        .overflow{
            overflow: auto;
        }
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .contact-communication{
            background-color: #fff !important;
            margin-left: -10px !important;
        }
        .communic-info{
            width: 100% !important;
        }
        .content-left{
            margin-left: -10px !important;
        }
        .infoHeader{
            width: 100% !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
        .contact-left{
            margin-left: -13px !important;
        }
    }
    @media only screen and (min-width:351px) and (max-width: 400px){
        .overflow{
            overflow: auto;
        }
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .contact-communication{
            background-color: #fff !important;
            margin-left: -10px !important;
        }
        .communic-info{
            width: 100% !important;
        }
        .content-left{
            margin-left: -10px !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .contact-left{
            margin-left: -13px !important;
        }
    }
    @media only screen and (min-width:401px) and (max-width: 500px){
        .overflow{
            overflow: auto;
        }
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .contact-communication{
            background-color: #fff !important;
            margin-left: -10px !important;
        }
        .communic-info{
            width: 100% !important;
        }
        .content-left{
            margin-left: -10px !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .contact-left{
            margin-left: -13px !important;
        }
    }
    @media only screen and (min-width:501px) and (max-width: 768px){
        .overflow{
            overflow: auto;
        }
        .iconsize{
            font-size: 12px !important;
        }
        .profile-text{
            font-size: 14px !important;
        }
        .contact-communication{
            background-color: #fff !important;
            margin-left: -10px !important;
        }
        .communic-info{
            width: 100% !important;
        }
        .content-left{
            margin-left: -10px !important;
        }
        .infoHeader{
            width: 100% !important;
            overflow: hidden !important;
        }
        .right-tab{
            margin-bottom: 150px !important;
        }
        .subHeaderTitle{
            font-size: 14px !important;
        }
        .profile-content-left-text-communication {
            width: 100%;
            min-width: 100%;
            position: relative;
            font-weight: 600;
        }
        .contact-left{
            margin-left: -13px !important;
        }
    }
    @media only screen and (min-width:769px) and (max-width: 1024px){
        .overflow{
            overflow: auto;
        }
    }
    .input-field-underline{
        color: #303031 !important;
        font-weight: 400 !important;
    }
    .readonly{
        cursor: no-drop !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
    .alt-number{
         font-weight: 400; 
         font-size:13px;
         padding-bottom: 0px !important;
         height:32px !important
    }
</style>